import React from 'react';


import { Link } from "react-router-dom";

import { Form, Input, Button, message } from 'antd';
import './createMember.css';
import { API_URL, post} from '../http/request'

// const onFinish = (values) => {
//   console.log('Success:', values);
// };
class App extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
      
  }

  
  
  onFinish(e){
    console.log('onFinish'+e);
    // this.props.history.push("/memberList")
   
    //这个可以跳到最外层
    // window.top.location.href = '/memberList';

    const success = (res) => {
      message.success(res.message); 
    };
    const fail = (res) => {
      message.error(res.errMsg);
    };
    post('/member/CreateMember', {...e}, success, fail);

    // const url=API_URL+'/member/CreateMember';
    // //调用fetch
    // fetch(url,{
    //     headers: {
    //       'content-type': 'application/json',
          
    //     },
    //     //请求方式
    //     method:'POST',
    //     body:JSON.stringify({...e, picUrlList:[], cropPicUrlList:[]})
        
    // // 请求的返回值
    // }).then((response) => {
    //   return response.json();
    // })
    // .then(res => {
    //   // console.log('&&&&&&:'+url);
    //   //获取请求的返回字段
    //   console.log(res);
    //   console.log(res.message);
    //   console.log(res.data);
    //   if (res.code == 0) {
    //     message.success(res.message);
    //   }else{
    //     message.error(res.errMsg);
    //   }

      

    // })
    // .catch(error => {
    //   console.log("出现一个问题alson:"+error);
    //   message.error("出现一个问题alson:"+error);
    // })
  }
  onReset(e){
    console.log('onReset'+e);
    
  }
  onValuesChange(e){
    console.log('onValuesChange'+e);
  }
  
  

  render() {
    const {items} = this.state;

    return (
      <div className="App">
          {/* <Link to="/alson/fdsf/vvvvv">link跳转测试</Link>
          <br></br>
          <a href="http://www.w3school.com.cn">W3School</a> */}
        
          {/* <li>创建场地****这里可以</li> */}

        

        <Form
        
        name="basic"
        // initialValues={{ remember: true }}
        onFinish={this.onFinish.bind(this)}
        onReset={this.onReset.bind(this)}
        onValuesChange={this.onValuesChange.bind(this)}
        >
          <div className='nameDiv'>
            <Form.Item className='nameItem'
              label="姓名"
              name="name"
              rules={[{ required: true, message: '请输入姓名！' }]}
            >
              <Input className='nameInput'
              autoComplete="on"
              />
              
            </Form.Item>
          </div>
          
          <div className='telDiv'>
            <Form.Item className='telItem'
              label="手机号"
              name="tel"
              rules={[{ required: true, message: '请输入手机号！' }]}
            >
              <Input  className='telInput'/>
            </Form.Item>
          </div>

          <div className='remainderDiv'>
            <Form.Item className='remainderItem'
              label="剩余次数"
              name="remainder"
              rules={[{ required: true, message: '请输入剩余次数！' }]}
            >
              <Input  className='remainderInput'/>
            </Form.Item>
          </div>

          {/* <Form.Item> */}
            <Button className='submit' type="primary" htmlType="submit">
              提交
            </Button>
            <Button className='reset' type="primary" htmlType="reset">
              重置
            </Button>
          {/* </Form.Item> */}
          
        </Form>
      
        
        
          
      </div>

      
    );
  }

  
  
}


export default App;
