
import React from 'react';

import axios from 'axios'
import qs from 'qs'

import AppUtils from '../Utils/AppUtils'
import CryptoJS from 'crypto-js';

export const API_URL = 'https://fitness.server.alsontop.cn/FitnessServerBoot/v_0.63'
// export const API_URL = 'http://8.135.152.240:50051/FitnessServerBoot/v_0.63'
// export const API_URL = 'http://172.20.10.9:30031/FitnessServerBoot/v_0.63'
// export const API_URL = 'http://localhost:30031/FitnessServerBoot/v_0.63'

const secretKey = 'alson'

function buildSign(params){

  var sign = '';
  var clearContent = '';
  var keyList=Object.keys(params).sort();
  //解决value不是字符串时的拼装问题
  for(let i in keyList){   
    // //为空的字段不加入校验
    // if (AppUtils.isNull(params[keyList[i]])) {
    //   continue;
    // }
    let type = Object.prototype.toString.call(params[keyList[i]]); 
    if (type == '[object Array]' || type == '[object Object]') {
      clearContent = clearContent + '&' + keyList[i] + '=' + JSON.stringify(params[keyList[i]]);
    }else{
      clearContent = clearContent + '&' + keyList[i] + '=' + params[keyList[i]];
    }
    console.log(keyList[i]+":"+params[keyList[i]]+",");
  }
  console.log("签名校验clearContent:" + clearContent); 
  sign = CryptoJS.HmacSHA256(clearContent, secretKey);
  sign = sign.toString().toUpperCase();
  console.log("HmacSHA256签名值:" + sign); 
  return sign;
 }

function request(method, path, params, data, success, fail){
  const url=API_URL+path;

  console.log('url:'+url);

  let wParams;
  if (method == 'get') {
    wParams = {...params};
  }
  if (method == 'post') {
    wParams = {...data};
  }

  // let lll = {};
  // // params.alsonTest = lll.kkk;
  // data.alsonTest = lll.kkk;

  //加上时间戳、requestId、token进行签名校验
  let timestamp = new Date().getTime();
  let requestId = AppUtils.getUUID();
  let token = localStorage.getItem('TOKEN');
  if (token == null) { //react网页端removeItem后，会是null，小程序则是'',
    token = '';
  }
  //去掉为空的字段
  var keyList=Object.keys(wParams).sort();
  for(let i in keyList){   
    if (AppUtils.isNull(wParams[keyList[i]])) {
      delete wParams[keyList[i]];
    } 
  }
  //js深拷贝
  let newParams = JSON.parse(JSON.stringify(wParams))
  newParams.timestamp = timestamp;
  newParams.requestId = requestId;
  newParams.token = token;
  let sign = buildSign(newParams);

  axios.request({
    url: url,
    method: method,
    data: wParams, //post数据
    params: wParams, //get数据
    //这是解决get参数为对象的情况(如果参数为对象，要先用JSON.stringify()转为json格式)
    paramsSerializer: _params => {
      let pp = qs.stringify(_params, { indices: true })
      // let pp = qs.stringify(params, { indices: false })
      return pp
    },
    headers:{
      "M-Timestamp": timestamp,
      'M-RequestId': requestId, //每个request请求的唯一id
      'M-Token': token,
      'M-Sign': sign,

      'content-type': 'application/json',
    }
  })
  .then((response) => {
    const token = response.headers['m-token'];
    if (token) {
      localStorage.setItem("TOKEN", token);// 刷新Token
      console.log('刷新Token：' + localStorage.getItem('TOKEN'));
    }
    return response.data;
  })
  .then(res => {
    // console.log('&&&&&&:'+url);
    //获取请求的返回字段
    console.log('返回结果:'+JSON.stringify(res));
    // console.log(res.message);
    // console.log(res.data);
    
    if (res.code == 0) {
      if (success) {
        success(res);
      }
    }else{
      if (fail) {
        fail(res);
      }
      if (res.code == 9004) { //身份验证失败，跳转登录页
        setTimeout(() => { 
          window.top.location.href = '/login';  
        }, 1000);
        
      }
    }
  })
  .catch(error => {
    console.log("出现一个问题alson"+error.message);
    if (fail) {
      fail({'code': -888, 'errMsg': error.message});
    }
    // message.error("出现一个问题alson:"+error);
  })
}

export function post(path, data, success, fail ){
  request('post', path, null, data, success, fail);
}
export function get(path, params, success, fail ){
  request('get', path, params, null, success, fail);
}

