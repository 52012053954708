import React from 'react';
import { Menu, message} from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import './home.css';
// import 'antd/dist/antd.css';

import { Link } from "react-router-dom";

import { API_URL, get} from '../src/http/request'
import axios from 'axios'
import qs from 'qs'

const { SubMenu } = Menu;

class Home extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      testUrl: '/createMember',
      adminInfo: {},
    };
  }

  componentDidMount() {
    
    console.log('这是主页');
    console.log('网页版本，v_0.6.4');

    const success = (res) => {
      // message.success(res.message); 
      this.setState({
        adminInfo: res.data,
      });
    };
    const fail = (res) => {
      message.error(res.errMsg);
    };
    get('/admin/FetchAdminDetails', {}, success, fail);

    // const url=API_URL+"/admin/FetchAdminDetails";
    
    // axios.get(url, {
    //   headers:{
    //     'Authorization': localStorage.getItem('TOKEN'),
    //   },
    //   params: {
    //     test:'mmnnnn',
    //     info: JSON.stringify({
    //       ll: 'vv',
    //       mm: 'uuu',
    //     }),
    //     list: JSON.stringify(['3', '4', '5']),
    //   },
    //   //这是解决get参数为对象的情况(如果参数为对象，要先用JSON.stringify()转为json格式)
    //   paramsSerializer: _params => {
    //     let pp = qs.stringify(_params, { indices: true })
    //     // let pp = qs.stringify(params, { indices: false })
    //     return pp
    //   }
    // })
    // .then((response) => {
    //   return response.data;
    // })
    // .then(res => {
    //   // console.log('&&&&&&:'+url);
    //   //获取请求的返回字段
    //   console.log(res);
    //   console.log(res.message);
    //   console.log(res.data);

    //   if (res.code == 0) {
    //     message.success(res.message);   
        
    //     this.setState({
    //       adminInfo: res.data,
    //     });
    //   }else{
    //     message.error(res.errMsg);
    //   }
    // })
    // .catch(error => {
    //   console.log("出现一个问题alson"+error);
    //   message.error("出现一个问题alson:"+error);
    // })
  }

  // _renderLayer(){//将弹层渲染到body下的div标签
  //   ReactDom.render(this.props.children, this.popup);
  // }

  componentWillReceiveProps(nextProps){
    console.log('dd');
  }

  handleClick(e) {
    console.log(this.props.match.params);
    const ll = this.props.match.params;
    console.log('有问题啊：'+ll.id);

    console.log('click ', e);
    // this.props.history.push("/alson/qqqq/qwwww")
    // this.setState({
    //   testUrl: '/memberList',
    // });

    if(e.key == '9'){
      this.setState({
        testUrl: '/createMember',
      });
    }
    if(e.key == '10'){
      this.setState({
        testUrl: '/memberList',
      });
    }    
    if(e.key == '11'){
      this.setState({
        testUrl: '/createSite',
      });
    }
    if(e.key == '12'){
      this.setState({
        testUrl: '/siteList',
      });
    }
    if(e.key == '14'){
      this.setState({
        testUrl: '/sessionList',
      });
      // this.props.history.push("/home/sessionList/mmmmm?type=90")
      
    }


  };

  render() {
    const {adminInfo} = this.state;

    return (
      <div>
        <div className='toolbar'>
          <div className='username'>{adminInfo.username+'，欢迎您'}</div>
          <a className='exit' onClick={()=>{this.exitClick()}}>退出登录</a>
        {/* <a href="http://www.w3school.com.cn">W3School</a> */}
        </div>
        <div className="testGroup">
            {/* <Link to="/alson/fdsf/vvvvv">link跳转测试</Link> */}
          
          
          <div className='testMenu'>
            <Menu 
            onClick={ e => this.handleClick(e)}
            // style={{ width: 256 }}
            defaultSelectedKeys={['9']}
            defaultOpenKeys={['sub4']}
            mode="inline"
            >
              <SubMenu key="sub4" icon={<SettingOutlined />} title='会员'>
                <Menu.Item key="9">创建会员信息</Menu.Item>
                <Menu.Item key="10">获取会员信息</Menu.Item>
              </SubMenu>
              <SubMenu key="sub5" icon={<SettingOutlined />} title='场地'>
                <Menu.Item key="11">创建场地</Menu.Item>
                <Menu.Item key="12">获取场地列表</Menu.Item>
              </SubMenu>
              <SubMenu key="sub6" icon={<SettingOutlined />} title='场次'>
                <Menu.Item key="13">创建场次</Menu.Item>
                <Menu.Item key="14">获取场次列表</Menu.Item>
              </SubMenu>
            </Menu>
          </div>

          {/* iframe内嵌网页 */}
          <iframe className='testFrame' src={this.state.testUrl}> </iframe>
        </div>
      </div>
      

      
    );
  }

  exitClick(){
    let pp = localStorage.getItem('TOKEN');
    localStorage.removeItem('TOKEN');
    this.props.history.push("/login");
    let ll = localStorage.getItem('TOKEN');
    message.success('成功退出');   
  }

  // gotoTest(){
  //   console.log('跳转测试');
  //   this.props.history.push("/Alson/ggg/nn")
  // }
  
}


export default Home;
