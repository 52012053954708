import React from 'react';


import { Link } from "react-router-dom";

import { Form, Input, Button, message } from 'antd';

import { API_URL, post} from '../src/http/request'
import axios from 'axios'
import qs from 'qs'
// import localStorage from "localStorage";
import './login.css';

class App extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    
      
  }

  
  
  onFinish(params){
    console.log('onFinish'+params);
    // this.props.history.push("/memberList")
   
    // 这个可以跳到最外层
    // window.top.location.href = '/memberList';

    const success = (res) => {
      message.success(res.message); 
      this.props.history.push("/home/sdf/sdfgggg")
    };
    const fail = (res) => {
      message.error(res.errMsg);
    };
    post('/admin/Login', params, success, fail);

    // const url=API_URL+'/admin/Login';

    // axios.request({
    //   url: url,
    //   method: 'post',
    //   data: params,
    //   headers:{
    //     'content-type': 'application/json',
    //   }
    // })
    // .then((response) => {
    //   const token = response.headers['m-token'];
    //   if (token) {
    //     localStorage.setItem("TOKEN", token);// 刷新Token
    //     console.log('刷新Token：' + localStorage.getItem('TOKEN'));
    //   }
    //   return response.data;
    // })
    // .then(res => {
    //   // console.log('&&&&&&:'+url);
    //   //获取请求的返回字段
    //   console.log(res);
    //   console.log(res.message);
    //   console.log(res.data);
      
    //   if (res.code == 0) {
    //     this.props.history.push("/home/sdf/sdfgggg")
    //     message.success(res.message);        
    //   }else{
    //     message.error(res.errMsg);
    //   }
    // })
    // .catch(error => {
    //   console.log("出现一个问题alson"+error);
    //   message.error("出现一个问题alson:"+error);
    // })

    
    // axios.post(url, params, {
    //   // params: params,
    //   headers:{
    //     'content-type': 'application/json',
    //   }
      
    // })
    // .then((response) => {
    //   const token = response.headers['m-token'];
    //   if (token) {
    //     localStorage.setItem("TOKEN", token);// 刷新Token
    //     console.log('刷新Token：' + localStorage.getItem('TOKEN'));
    //   }
    //   return response.data;
    // })
    // .then(res => {
    //   // console.log('&&&&&&:'+url);
    //   //获取请求的返回字段
    //   console.log(res);
    //   console.log(res.message);
    //   console.log(res.data);
      
    //   if (res.code == 0) {
    //     this.props.history.push("/home/sdf/sdfgggg")
    //     message.success(res.message);        
    //   }else{
    //     message.error(res.errMsg);
    //   }
    // })
    // .catch(error => {
    //   console.log("出现一个问题alson"+error);
    //   message.error("出现一个问题alson:"+error);
    // })

    
  }
  onReset(e){
    console.log('onReset'+e);
  }
  onValuesChange(e){
    console.log('onValuesChange'+e);
  }
  
  

  render() {
    const {items} = this.state;

    return (
      <div className="App">
          {/* <Link to="/alson/fdsf/vvvvv">link跳转测试</Link>
          <br></br>
          <a href="http://www.w3school.com.cn">W3School</a> */}
        
          {/* <li>创建场地****这里可以</li> */}

        

        <Form
        name="basic"
        // initialValues={{ remember: true }}
        onFinish={this.onFinish.bind(this)}
        onReset={this.onReset.bind(this)}
        onValuesChange={this.onValuesChange.bind(this)}
        >
          <div className='nameDiv'>
            <Form.Item className='nameItem'
              label="用户名_2022_改了"
              name="username"
              rules={[{ required: true, message: '请输入用户名！' }]}
            >
              <Input className='nameInput' 
                autoComplete="off"
              />
            </Form.Item>
          </div>
          
          <div className='addressDiv'>
            <Form.Item className='addressItem'
              label="密码"
              name="password"
              rules={[{ required: true, message: '请输入密码！' }]}
            >
              <Input  className='addressInput'
                autoComplete="off"
              />
            </Form.Item>
          </div>

          <Button className='submit' type="primary" htmlType="submit">
            登录
          </Button>
          <Button className='reset' type="primary" htmlType="reset">
            重置
          </Button>
          
        </Form>
      
        
        
          
      </div>

      
    );
  }

  
  
}


export default App;
