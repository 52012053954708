import React from 'react';

// import { Link } from "react-router-dom";
import { Table, Pagination, Tag, message } from 'antd';

import './siteList.css'

import { API_URL, get} from '.././http/request'

class Home extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      sitePageInfo: {},
      dataSource: [],
    };
  }

  componentDidMount() {

    
    this.fetchSiteList(1);
  }

  fetchSiteList(page){
    
    const success = (res) => {
      message.success(res.message); 
      this.state.sitePageInfo = res.data;
      this.makeSourceData();
    };
    const fail = (res) => {
      message.error(res.errMsg);
    };
    get('/site/FetchSiteList', {page: page, limit: 7}, success, fail);



    // const url= API_URL+"/site/FetchSiteList?page="+page+'&limit=7';    
    // //调用fetch
    // fetch(url,{
    //     //请求方式
    //     method:'GET',
        
    // // 请求的返回值
    // }).then((response) => {
    //   return response.json();
    // })
    // .then(res => {
    //   // console.log('&&&&&&:'+url);
    //   //获取请求的返回字段
    //   console.log(res);
    //   console.log(res.message);
    //   console.log(res.data);
    //   // this.setState({
    //   //   sitePageInfo: res.data,
    //   // });
    //   this.state.sitePageInfo = res.data;
    //   this.makeSourceData();
    // })
    // .catch(error => {
    //   console.log("出现一个问题alson"+error);
    // })
  }

  makeSourceData(){
    let {sitePageInfo} = this.state;

    let dataSource = [];
    sitePageInfo.list.map((site, index) => {
      let info = {};
      info.key = site.id;
      info.name = site.name;
      info.address = site.address;
      info.createTime = site.createTime;
      dataSource.push(info);

    })
    this.state.testStr = 'bbbb';
    this.setState({
      dataSource: dataSource,
    });
    console.log('');
  }

  pageChange(pageInfo){
    console.log('page');
    // this.state.sitePageInfo = [];
    // this.setState({});
    this.fetchSiteList(pageInfo.current);
  }

  onNameClick(name){
    console.log('onNameClick:'+name);
  }

  render() {
    const {sitePageInfo, dataSource, testStr} = this.state;

    const columns = [
      {
        title: '场地名称',
        dataIndex: 'name',
        key: 'name',
        render: text => <a onClick={this.onNameClick.bind(this, text)}>{text}</a>,
      },
      {
        title: '场地地址',
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime',
      },
      {
        title: '操作',
        // dataIndex: 'id',
        key: 'action',
        render: (text, record, index) => <a onClick={this.onDetailClick.bind(this,index)} >详情</a>,
      },
    ]


    
    
    const PageHelper = {
      current: sitePageInfo.page,
      pageSize: sitePageInfo.limit,
      total: sitePageInfo.count,
      showSizeChanger: false,
    };



    return (
      <div className="testDiv">
        {/* <Link to="/alson/fdsf/vvvvv">link跳转测试</Link>
      
        <li>获取场地列表*******999</li> */}

        <Table className='testTable' bordered={true} onChange={(pageInfo) => this.pageChange(pageInfo)} pagination={PageHelper} columns={columns} dataSource={dataSource} />
          

        
          
      </div>
    );
  }

  onDetailClick(index){
    const {sitePageInfo} = this.state;
    const siteId = sitePageInfo.list[index].id;
    this.props.history.push('/siteDetails/'+siteId);
  }
  
}


export default Home;
