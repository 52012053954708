import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

import Router from './router';

import zh_CN from 'antd/lib/locale-provider/zh_CN'//全局设置antd中文
import { ConfigProvider } from 'antd'
import 'antd/dist/antd.css'; //全局引入样式

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  // document.getElementById('root')
  
  //全局设置antd中文
  <ConfigProvider locale={zh_CN}>
    <Router/>
  </ConfigProvider>,
  
  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
