
import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import App from './App';
import Alson from './alson';

import Main from './main';
import Login from './login';
import Home from './home'
import CreateMember from './member/createMember'
import MemberList from './member/memberList'
import SiteList from './site/siteList'
import SiteDetails from './site/siteDetails'
import CreateSite from './site/createSite'
import SessionList from './session/sessionList'

const BasicRoute = () => (
    <Router>
        <Switch>
            <Route exact path="/app" component={App}/>
            <Route exact path="/alson/:id/:pp" component={Alson}/>

            {/* 支持多种传参方式 */}
            <Route exact path="/" component={Main}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/home/:id/:pp" component={Home}/>
            <Route exact path="/home/:id" component={Home}/>
            <Route exact path="/createMember" component={CreateMember}/>
            <Route exact path="/memberList" component={MemberList}/>
            <Route exact path="/siteList" component={SiteList}/>
            <Route exact path="/createSite" component={CreateSite}/>
            <Route exact path="/siteDetails/:siteId" component={SiteDetails}/>
            <Route exact path="/sessionList" component={SessionList}/>
        </Switch>
    </Router>
);


export default BasicRoute;
