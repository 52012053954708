
import React from 'react';
import { Menu } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
// import 'antd/dist/antd.css';
import './alson.css'

const { SubMenu } = Menu;

class alson extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      testUrl: '/',
    };
  }

  componentDidMount(){

    // console.log(this.props.match.params);
    // const ll = this.props.match.params;
    // console.log('有问题啊：'+ll.id);
    console.log('ggggg');
  }

  componentDidUpdate(){
    console.log('mmmm');
  }

  handleClick = e => {
    console.log(this.props.match.params);
    const ll = this.props.match.params;
    console.log('有问题啊：'+ll.id);

    console.log('click ', e);
    this.props.history.push("/alson/qqqq/qwwww")
    // this.setState({
    //   testUrl: '/alson/sfff/mmmmm',
    // });

  };

  render() {
    return (
      <div className='testGroup'>
        <Menu className='testMenu'
        onClick={this.handleClick}
        // style={{ width: 256 }}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        >
          <SubMenu key="sub1" icon={<MailOutlined />} title="Navigation One">
            <Menu.ItemGroup key="g1" title="Item 1">
              <Menu.Item key="1">Option 1</Menu.Item>
              <Menu.Item key="2">Option 2</Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="g2" title="Item 2">
              <Menu.Item key="3">Option 3</Menu.Item>
              <Menu.Item key="4">Option 4</Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Navigation Two">
            <Menu.Item key="5">Option 5</Menu.Item>
            <Menu.Item key="6">Option 6</Menu.Item>
            <SubMenu key="sub3" title="Submenu">
              <Menu.Item key="7">Option 7</Menu.Item>
              <Menu.Item key="8">Option 8</Menu.Item>
            </SubMenu>
          </SubMenu>
          <SubMenu key="sub4" icon={<SettingOutlined />} title="Navigation Three">
            <Menu.Item key="9">Option 9</Menu.Item>
            <Menu.Item key="10">Option 10</Menu.Item>
            <Menu.Item key="11">Option 11</Menu.Item>
            <Menu.Item key="12">Option 12</Menu.Item>
          </SubMenu>
        </Menu>

        
        <iframe className='testFrame' src={this.state.testUrl}> </iframe>
        {/* <li className='testLabel'>牛奶</li> */}
      </div>
      
    );
  }
}

export default alson;




