import React from 'react';

// import { Link } from "react-router-dom";
import { Image, message } from 'antd';

import './siteDetails.css'

import { API_URL, get} from '.././http/request'

class Home extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      siteInfo: {
        picUrlList:[],
        cropPicUrlList: [],
      },
    };
  }

  componentDidMount() {

    const success = (res) => {
      message.success(res.message); 
      this.setState({
        siteInfo: res.data,
      });
    };
    const fail = (res) => {
      message.error(res.errMsg);
    };
    get('/site/FetchSiteDetails', {id: this.props.match.params.siteId}, success, fail);



  //   const url=API_URL+"/site/FetchSiteDetails?id="+this.props.match.params.siteId;
  //   //请求的参数
  //   //调用fetch
  //   fetch(url,{
  //     //请求方式
  //     method:'GET',
    
  // // 请求的返回值
  // }).then((response) => {
  //   return response.json();
  // })
  //   .then(res => {
  //     // console.log('&&&&&&:'+url);
  //     //获取请求的返回字段
  //     console.log(res);
  //     console.log(res.message);
  //     console.log(res.data);
  //     this.setState({
  //       siteInfo: res.data,
  //     });
  //   })
  //   .catch(error => {
  //     console.log("出现一个问题alson"+error);
  //   })
      
  }

  

  

  render() {
    
    const {siteInfo} = this.state;


    return (
      <div className="testDiv">
        {/* <Link to="/alson/fdsf/vvvvv">link跳转测试</Link>
      
        <li>获取场地列表详情页</li> */}

          
        <div className='labelGroup'>
          {/* <label className='picLabel'>场地图片：</label> */}
          

          <div className='nameGroup'>
            <label className='nameLabel'>场地名称：</label>
            <label className='name'>{siteInfo.name}</label>
          </div>

          <div className='addressGroup'>
            <label className='addressLabel'>场地地址：</label>
            <label className='address'>{siteInfo.address}</label>
          </div>

          <div className='picLabelGroup gg'>场地图片： </div>

          <div className='picImageGroup'>

            {/* {
              siteInfo.cropPicUrlList.map((picUrl, index) => {
                return(
                  
                  <label className='image'>水电费：</label>
                )
              })
            } */}

            {/* {
              siteInfo.cropPicUrlList.map((picUrl, index) => {
                return(
                  
                    <Image 
                      className='image'
                      key={picUrl}
                      // width={170}
                      src={picUrl}
                      preview={{
                        src: siteInfo.picUrlList[index],
                      }}
                    />
                )
              })
            } */}

            <Image.PreviewGroup className='imageGroup'>
              {
                siteInfo.cropPicUrlList.map((picUrl, index) => {
                  return(
                    
                      <Image 
                        className='image'
                        key={picUrl}
                        src={picUrl}
                        // src={siteInfo.picUrlList[index]}
                        preview={{
                          src: siteInfo.picUrlList[index],
                        }}
                      />
                  )
                })
              }
            </Image.PreviewGroup>
          </div>
          
        </div>

        
        
        
        
          
      </div>
    );
  }

  
}


export default Home;
