import React from 'react';
// import './App.css';

import { Link } from "react-router-dom";
import { Table, Pagination, Select, Form, message, Button } from 'antd';

import './sessionList.css'

import { API_URL, get} from '../http/request'

import axios from 'axios'
import qs from 'qs'

class Home extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      sessionList: [],
      dataSource: [],

      memberList: [], //会员筛选条件时获取的所有会员信息
    };
  }

  componentDidMount() {
    
    this.fetchList({});
    
      
  }

  fetchList(params){

    const success = (res) => {
      message.success(res.message); 
      this.state.sessionList = res.data;
      this.makeSourceData();
    };
    const fail = (res) => {
      message.error(res.errMsg);
    };
    get('/session/FetchSessionList', params, success, fail);


    // const url=API_URL+"/session/FetchSessionList";

    // axios.request({
    //   url: url,
    //   method: 'get',
    //   headers:{
    //     'content-type': 'application/json',
    //     'Authorization': localStorage.getItem('TOKEN'),
    //   },
    //   params: params,
    //   //这是解决get参数为对象的情况
    //   paramsSerializer: _params => {
    //     //这个是序列化之后的参数"ids=%5B1%2C2%2C3%2C4%5D&type=2&filterList=%5B%7B%22kind%22%3A1%2C%22memberIdList%22%3A%5B2%5D%7D%5D"
    //     let pp = qs.stringify(_params, { indices: true })
    //     // let pp = qs.stringify(params, { indices: false })
    //     return pp
    //   }
    // })
    // .then((response) => {
    //   return response.data;
    // })
    // .then(res => {
    //   // console.log('&&&&&&:'+url);
    //   //获取请求的返回字段
    //   console.log(res);
    //   console.log(res.message);
    //   console.log(res.data);
 
    //   if (res.code == 0) {
    //     message.success(res.message);   
        
    //     this.state.sessionList = res.data;
    //     this.makeSourceData();
    //   }else{
    //     message.error(res.errMsg);
    //   }
    // })
    // .catch(error => {
    //   console.log("出现一个问题alson"+error);
    //   message.error("出现一个问题alson:"+error);
    // })

    
    // axios.get(url, {
    //   headers:{
    //     'Authorization': localStorage.getItem('TOKEN'),
    //   },
    //   // withCredentials:true,
    //   params: params,
    //   //这是解决get参数为对象的情况
    //   paramsSerializer: _params => {
    //     //这个是序列化之后的参数"ids=%5B1%2C2%2C3%2C4%5D&type=2&filterList=%5B%7B%22kind%22%3A1%2C%22memberIdList%22%3A%5B2%5D%7D%5D"
    //     let pp = qs.stringify(_params, { indices: true })
    //     // let pp = qs.stringify(params, { indices: false })
    //     return pp
    //   }
    // })
    // .then((response) => {
    //   return response.data;
    // })
    // .then(res => {
    //   // console.log('&&&&&&:'+url);
    //   //获取请求的返回字段
    //   console.log(res);
    //   console.log(res.message);
    //   console.log(res.data);
 
    //   if (res.code == 0) {
    //     message.success(res.message);   
        
    //     this.state.sessionList = res.data;
    //     this.makeSourceData();
    //   }else{
    //     message.error(res.errMsg);
    //   }
    // })
    // .catch(error => {
    //   console.log("出现一个问题alson"+error);
    //   message.error("出现一个问题alson:"+error);
    // })
  }

  makeSourceData(){
    let {sessionList} = this.state;

    let dataSource = [];
    sessionList.map((session, index) => {
      const jointDate = session.date+' '+session.beginTime+'-'+session.endTime;
      const stateStr = session.state == 1 ? '预约中' : '已签到';
      let memberNameStr = '';
      session.memberList.forEach(memberName => {
        memberNameStr = memberNameStr + memberName + ', ';
      });
      let info = {};
      info.key = session.id;
      info.siteName = session.siteInfo.name;
      info.courseName = session.courseName;
      info.teacherName = session.teacherName;
      info.jointDate = jointDate;
      info.stateStr = stateStr;
      info.memberNameStr = memberNameStr;
      dataSource.push(info);

    })
    this.setState({
      dataSource: dataSource,
    });
    console.log('');
  }

  pageChange(page, pageSize, total){
    console.log('page');
    // this.state.sessionList = [];
    this.setState();
  }

  render() {
    const {sessionList, dataSource, memberList} = this.state;

    const columns = [
      {
        title: '场地名称',
        dataIndex: 'siteName',
        key: 'siteName',
        render: text => <a>{text}</a>,
      },
      {
        title: '课程名称',
        dataIndex: 'teacherName',
        key: 'teacherName',
      },
      {
        title: '老师姓名',
        dataIndex: 'courseName',
        key: 'courseName',
      },
      {
        title: '时间段',
        dataIndex: 'jointDate',
        key: 'jointDate',
      },
      {
        title: '状态',
        dataIndex: 'stateStr',
        key: 'stateStr',
      }, 
      {
        title: '已预约会员',
        dataIndex: 'memberNameStr',
        key: 'memberNameStr',
      }, 
      
    ]
    
    const PageHelper = {
      current:9,
      pageSize:8,
      total:120,
      showSizeChanger: false,
    };

    return (
      <div className="testDiv">          

          <Form
            name="basic"
            // initialValues={{ remember: true }}
            onFinish={this.onFinish.bind(this)}
            // onReset={this.onReset.bind(this)}
            // onValuesChange={this.onValuesChange.bind(this)}
            // onFinishFailed={onFinishFailed}
          >
            <Form.Item className='nameItem'
              label="根据会员筛选"
              name="memberIdList"
              // rules={[{ required: true, message: '请输入场地名称！' }]}
            >
              <Select
                mode="multiple"
                allowClear
                // showArrow
                // showSearch
                style={{ width: 300 }}
                placeholder="Please select"
                // defaultValue={['a10', 'c12']}
                onChange={e =>{this.handleChange(e)}}
                onFocus = {this.onMemberFilterFocus.bind(this)}
                // loading
              >
                
                {//数组要设置key={member.id}，否则有警告
                  memberList.map((member,index)=>{
                    return(
                      <Select.Option key={member.id} value={member.id}>{member.name}</Select.Option>
                      
                    )
                  })
                } 
                
                
                {/* <div>
                  <Select.Option value="tzzz">tzzz</Select.Option>
                  <Select.Option value="lucy">Lucy</Select.Option>
                  <Select.Option value="alson">alson</Select.Option>
                  <Select.Option value="test">test</Select.Option>
                </div> */}
                
                
              </Select>
            </Form.Item>

            <Form.Item className='nameItem'
              label="根据状态筛选"
              name="state"
              // rules={[{ required: true, message: '请输入场地名称！' }]}
            >
              <Select
                // mode="multiple"
                allowClear
                // showArrow
                // showSearch
                style={{ width: 300 }}
                placeholder="Please select"
                // defaultValue={['a10', 'c12']}
                onChange={e =>{this.handleChange(e)}}
                // onFocus = {this.onFocus.bind(this)}
                
                // loading
              >
                <Select.Option value={1}>预约中</Select.Option>
                <Select.Option value={2}>已签到</Select.Option>
                
              </Select>
            </Form.Item>

            {/* <Form.Item className='addressItem'
              label="场地地址"
              name="address"
              rules={[{ required: true, message: '请输入场地地址！' }]}
            >
              <Input  className='addressInput'/>
            </Form.Item> */}

            {/* <Form.Item  name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

            <Form.Item>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="reset">
                重置
              </Button>
            </Form.Item>
            
          </Form>
          
          

          

          <Table className='testTable' bordered={true} onChange={(page) => this.pageChange(page)} pagination={false} columns={columns} dataSource={dataSource} />
                  
      </div>
    );
  }

  //提交筛选
  onFinish(e){
    console.log(e);
    e.memberIdList = JSON.stringify(e.memberIdList);

    let params = {
      ...e,
    }
    this.fetchList(params);
  }

  handleChange(e){
    console.log(e);
  }

  //点击会员筛选框时触发
  onMemberFilterFocus(){
    console.log('onFocus');

    const success = (res) => {
      // message.success(res.message); 
      this.setState({
        memberList: res.data,
      });
      
    };
    const fail = (res) => {
      message.error(res.errMsg);
    };
    get('/member/FetchMemberList', {}, success, fail);


    // //获取会员信息
    // const url=API_URL+"/member/FetchMemberList";
    
    // //调用fetch
    // fetch(url,{
    //     //请求方式
    //     method:'GET',
    //     //将请求的参数转成json
    //     // body:json ,
    //     // //请求头
    //     // headers: {
    //     //     'content-type': 'application/json'
    //     // }
    // // 请求的返回值
    // }).then((response) => {
    //   return response.json();
    // })
    //   .then(res => {
    //     // console.log('&&&&&&:'+url);
    //     //获取请求的返回字段
    //     console.log(res);
    //     console.log(res.message);
    //     console.log(res.data);
    //     if (res == 0) {
    //       this.setState({
    //         memberList: res.data,
    //       });
    //     }
        
        
    //   })
    //   .catch(error => {
    //     console.log("出现一个问题alson"+error);
    //   })
  }

  // gotoTest(){
  //   console.log('跳转测试');
  //   this.props.history.push("/Alson/ggg/nn")
  // }
  
}


export default Home;
