
import React from 'react';

export default {
    
    isEmpty(val) {
        if(val == null || val == undefined || val == '') {
            return true;
        } else {
            return false;
        }
    },

    /**
     * 判断是否为null、undefined
     */
    isNull(val) {
        if(val == null || val == undefined) {
            return true;
        } else {
            return false;
        }
    },

    /**
     * 获取uuid
     */
    getUUID() {
        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r&0x3|0x8)).toString(16);
        });
        return uuid;
    },

}