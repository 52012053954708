import React from 'react';
// import './App.css';

import { Link } from "react-router-dom";
import { Table, Pagination, Tag, message } from 'antd';
import ExportJsonExcel from 'js-export-excel';

import './memberList.css'

import { API_URL, get} from '../http/request'

class Home extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      memberList: [],
      dataSource: [],
      testStr: '',
    };
  }

  componentDidMount() {
    
    const success = (res) => {
      message.success(res.message); 
      this.state.memberList = res.data;
      this.makeSourceData();
    };
    const fail = (res) => {
      message.error(res.errMsg);
    };
    get('/member/FetchMemberList', {}, success, fail);



    // const url=API_URL+"/member/FetchMemberList";
    
    
    // //调用fetch
    // fetch(url,{
    //     // credentials: 'include',
    //     //请求方式
    //     method:'GET',
    //     //将请求的参数转成json
    //     // body:JSON.stringify(param) ,
    //     // //请求头
    //     headers: {
    //       'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoxLCJleHAiOjE2MjY0MTkwOTV9.C77wIWzdsOu1LqC7CFXh4gQ7SUcSFYmNzDn5PFvdKqk'
          
    //         // 'content-type': 'application/json'
    //     }
    // // 请求的返回值
    // }).then((response) => {
    //   return response.json();
    // })
    //   .then(res => {
    //     // console.log('&&&&&&:'+url);
    //     //获取请求的返回字段
    //     console.log(res);
    //     console.log(res.message);
    //     console.log(res.data);
    //     // this.setState({
    //     //   memberList: res.data,
    //     // });
    //     this.state.memberList = res.data;
    //     this.makeSourceData();
    //   })
    //   .catch(error => {
    //     console.log("出现一个问题alson"+error);
    //   })
      
  }

  makeSourceData(){
    let {memberList} = this.state;

    let dataSource = [];
    memberList.map((member, index) => {
      let info = {};
      info.key = member.id;
      info.name = member.name;
      info.tel = member.tel;
      info.remainder = member.remainder;
      dataSource.push(info);

    })
    this.state.testStr = 'bbbb';
    this.setState({
      dataSource: dataSource,
    });
    console.log('');
  }

  pageChange(page, pageSize, total){
    console.log('page');
    // this.state.memberList = [];
    this.setState();
  }

  render() {
    const {memberList, dataSource, testStr} = this.state;

    const columns = [
      {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        render: text => <a>{text}</a>,
      },
      {
        title: '电话',
        dataIndex: 'tel',
        key: 'tel',
      },
      {
        title: '剩余次数',
        dataIndex: 'remainder',
        key: 'remainder',
      },
    ]

    const data = [
      {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
      },
      {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
      },
      {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
      },
    ];

    
    
    const PageHelper = {
      current:9,
      pageSize:8,
      total:120,
      showSizeChanger: false,
    };



    return (

   
 
      <div className="testDiv">
                  
          <button onClick={this.exportTable}>导出</button>
          <Table className='testTable' bordered={true} onChange={(page) => this.pageChange(page)} pagination={false} columns={columns} dataSource={dataSource} />
          
      </div>
    );
  }

  //将table数据导出为excel文件
  exportTable(){
    // const { getRepaymentPlanList } = this.props;  //从props中获取数据源
		let option = {};  //option代表的就是excel文件
		let dataTable = [];  //excel文件中的数据内容
		let obj = {
      '还款期数': 3,
      '计划还款日': 5,
      '租金（元）': 1000,
      '还款本金': 2000,
      '还款利息': '周生',
    }
    dataTable.push(obj);  //设置excel中每列所获取的数据源
    option.fileName = '实际还款计划alson';  //excel文件名称
    option.datas = [
      {
        sheetData: dataTable,  //excel文件中的数据源
        sheetName: '实际还款计划',  //excel文件中sheet页名称
        sheetFilter: ['还款期数', '计划还款日', '租金（元）', '还款本金', '还款利息'],  //excel文件中需显示的列数据
        sheetHeader: ['还款期数', '计划还款日', '租金（元）', '还款本金', '还款利息'],  //excel文件中每列的表头名称
      }
    ]
    let toExcel = new ExportJsonExcel(option);  //生成excel文件
    toExcel.saveExcel();  //下载excel文件
  }


  gotoTest(){
    console.log('跳转测试');
    this.props.history.push("/Alson/ggg/nn")
  }
  
}
export default Home;
