import React from 'react';
// import logo from './logo.svg';
import './App.css';
// import { render } from '@testing-library/react';
// import fetch from 'dva/fetch';
import { Link } from "react-router-dom";

// import Alson from './alson';

class App extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {

    this.props.history.push("/login")
    // return;
    //请求的url



    const url="http://8.135.152.240:50051/FitnessServerMvc/member/FetchMemberList";

    // const url="http://172.20.10.9:8080/FitnessServerMvc/member/FetchMemberList";
    // const url="http://172.20.10.9:8080/FitnessServerMvc/site/FetchSiteList";
    //请求的参数
    const param={
        name:"admin",
        pwd:"123456"
    };
    //调用fetch
    fetch(url,{
        //请求方式
        method:'GET',
        //将请求的参数转成json
        // body:JSON.stringify(param) ,
        // //请求头
        // headers: {
        //     'content-type': 'application/json'
        // }
    // 请求的返回值
    }).then((response) => {
      return response.json();
    })
      .then(res => {
        // console.log('&&&&&&:'+url);
        //获取请求的返回字段
        console.log(res);
        console.log(res.message);
        console.log(res.data);
        this.setState({
          items: res.data,
        });
      })
      .catch(error => {
        console.log("出现一个问题alson"+error);
      })
      // if(response.status===200){
        
      // }else {
      //   alert("出现一个问题");
      // }

      
  }


  render() {
    const {items} = this.state;

    return (
      <div className="App">
          <Link to="/alson/fdsf/vvvvv">link跳转测试</Link>
        {/* <Alson /> */}

        {/* <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reloa4455d.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header> */}

        <ul>
          {
            items.map((item, index) => {
              return(
                <li className='test' key={item.id}>{item.name}</li>
              )

            })
          }
          {/* {['dfd','fdfdddd']} */}
          {/* <li>咖啡</li>
          <li>茶</li>
          <li>牛奶</li> */}
        </ul>

        <ul>
          <a href="http://www.w3school.com.cn">W3School</a>
          <li onClick={this.gotoTest.bind(this)}>咖啡23</li>
          <li>茶
          
          </li>
          <li>牛奶</li>
        </ul>

      </div>
    );
  }

  gotoTest(){
    console.log('跳转测试');
    this.props.history.push("/Alson/ggg/nn")
  }
  
}


export default App;
